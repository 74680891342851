import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './Home.module.css';

import Button from '../../components/Button/Button';

import notepad_drawing from '../../assets/home/notepad_drawing.svg';
import presentation_drawing from '../../assets/home/presentation_drawing.svg';
import computer_drawing from '../../assets/home/computer_drawing.svg';
import download_drawing from '../../assets/home/download_drawing.svg';

// import Features from '../../components/Features/Features';

const Home = () => {
  return (
    <div className={Styles.Home}>
      {/* <Features /> */}

      <div className={Styles.MainWrapper}>
        <div className={Styles.MainContainer}>
          <div className={Styles.MainContentBox}>
            <div className={Styles.PrimaryContentLayout}>
              <div className={Styles.PrimaryContentColumn}>
                <p className={Styles.MainHeading}>
                  {' '}
                  Too busy writing in lectures to listen ?
                </p>
                <p className={Styles.SubHeading}> NoteMaker allows you to:</p>
                <ul>
                  <li>
                    <span style={{ marginLeft: '10px' }}>
                      Quickly generate comprehensive notes
                    </span>
                  </li>
                  <li>
                    <span style={{ marginLeft: '10px' }}>
                      Purely written from the information provided
                    </span>
                  </li>
                  <li>
                    <span style={{ marginLeft: '10px' }}>Easy to use</span>
                  </li>
                </ul>
                {/* <br></br> */}
                <Button label='Get Started' size='lg' />
              </div>
              <img src={notepad_drawing} alt='Reading' />
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.SecondaryContentBackgroundImage}>
        <div className={Styles.MainWrapper}>
          <div className={Styles.MainContainer}>
            <div className={Styles.MainContentBox}>
              <div className={Styles.SecondaryContent}>
                <p className={Styles.SecondaryHeading}>
                  {' '}
                  🤔 How does it work? 🤨{' '}
                </p>
                <div className={Styles.HowItWorksCard}>
                  <div className={Styles.HowItWorksText}>
                    <h2>
                      1️⃣
                      <br />
                      Upload your slides{' '}
                    </h2>
                    <p>
                      {' '}
                      Upload a PDF of your lecture notes to the NoteMaker
                      website.{' '}
                    </p>
                  </div>
                  <img src={presentation_drawing} alt='Presentation' />
                </div>

                <div className={Styles.HowItWorksCard}>
                  <div className={Styles.HowItWorksText}>
                    <h2>
                      2️⃣
                      <br /> Generate your notes{' '}
                    </h2>
                    <p> Our AI will then generate notes from your slides. </p>
                  </div>
                  <img src={computer_drawing} alt='Computer' />
                </div>

                <div className={Styles.HowItWorksCard}>
                  <div className={Styles.HowItWorksText}>
                    <h2>
                      3️⃣
                      <br />
                      Download your notes{' '}
                    </h2>
                    <p>
                      {' '}
                      Your new notes will be written in Markdown, a format that
                      can be read by popular apps like{' '}
                      <a href='https://www.notion.so/'>Notion</a> and{' '}
                      <a href='https://obsidian.md/'> Obsidian</a>.
                    </p>
                  </div>
                  <img src={download_drawing} alt='Download' />
                </div>

                <Link to='/usage'>
                  <Button label='Get Started' size='lg' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
