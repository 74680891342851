import React, { useState } from 'react';
import Styles from './nav.module.css';
// import cw from 'classnames';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import { Link } from 'react-router-dom';

const Nav = ({ children, logo }) => {
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false);

  const isBigScreen = useMediaQuery({ query: '(min-width: 1010px)' });

  const handleMenuOpen = () => {
    setMobileHeaderOpen((current) => !current);
  };

  return (
    <div className={Styles.Header}>
      <ul className={Styles.Brand}>
        <li className={Styles.Logo}>
          <Link to='/'>
            <img alt='Statboard' src={logo} />
          </Link>
        </li>
      </ul>
      {(mobileHeaderOpen || isBigScreen) && (
        <ul className={Styles.Navigation}>{children}</ul>
      )}
      <div className={Styles.MobileClose}>
        <button onClick={() => handleMenuOpen()}>☰</button>
      </div>
    </div>
  );
};

Nav.propTypes = {
  user: PropTypes.object,
};
export default Nav;
