import React from 'react';
import cw from 'classnames';
import PropTypes from 'prop-types';

import Styles from './panel.module.css';
// import { cloneElement } from 'react';

const Panel = ({ children, fill, flexSize }) => {
  return (
    <div
      style={{ flex: flexSize }}
      className={cw(Styles.panel, fill ? Styles.fill : [])}
    >
      {children}
    </div>
  );
};

// rowSizesExample = [1,2]

const Row = ({ children, rowSizes, Center }) => {
  return (
    <div className={Styles.Row_wrapper}>
      <div style={{ justifyContent: 'center' }} className={Styles.Row}>
        {React.Children.map(children, (child, i) => {
          if (
            child.type.displayName === 'Panel' ||
            child.type.displayName === 'SplitPanel' ||
            child.type.displayName === 'Tab'
          ) {
            var alteredChild = React.cloneElement(child, {
              fillWidth: Center ? false : true,
              flexSize: Center ? 'none' : rowSizes[i],
            });
            return alteredChild;
          }
        })}
      </div>
    </div>
  );
};

Panel.propType = {
  fillWidth: PropTypes.bool,
};
Row.propType = {
  rowSizes: PropTypes.arrayOf(PropTypes.number),
};

Panel.displayName = 'Panel';
Panel.Row = Row;

export default Panel;
