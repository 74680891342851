import React from 'react';
import Styles from './Usage.module.css';
import axios from 'axios';

import Panel from '../../components/Panel/Panel';
import Button from '../../components/Button/Button';

import upload from '../../assets/usage/upload.svg';
import waiting_gif from '../../assets/usage/loading.gif';
import triangle_shape from '../../assets/usage/triangle_shape.svg';

const Usage = () => {
  const [waiting, setWaiting] = React.useState(false);
  const [file, setFile] = React.useState(null);

  // When the user submits the pdf the frontend sends the backend the pdf for processing
  // The response of the backend is a markdown file containing the notes
  // An error response will be sent if the pdf is not processed correctly
  const submitHandler = () => {
    if (file != null) {
      // Get radio result for output type
      const markdown = document.getElementById('markdown').checked;

      const formData = new FormData();

      console.log('requested, wait...');
      // Use axios to send the file to the backend
      formData.append('file', file);
      formData.append('output', markdown ? 'markdown' : 'pdf');

      setWaiting(true);
      axios
        .post(
          'https://seal-app-6ckzb.ondigitalocean.app/pdf-to-notes',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob',
          }
        )
        .then((res) => {
          console.log(res);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;

          const disposition = res.headers['content-disposition'];
          let filename;
          if (markdown) filename = 'notes.md';
          else filename = 'notes.pdf';

          if (disposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
          link.setAttribute('download', filename);

          // if (markdown) link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
          // else link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);

          document.body.appendChild(link);
          link.click();
          setWaiting(false);
        })
        .catch((err) => {
          console.error(err);
          setWaiting(false);
        });
    } else {
      alert('Please upload a PDF file');
    }
  };

  function dropHandler(ev) {
    console.log('File(s) dropped');

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // If dropped items aren't files, reject them
      let item = ev.dataTransfer.items[0];
      if (item.kind === 'file' && item.type === 'application/pdf') {
        const file = item.getAsFile();
        setFile(file);
      } else {
        alert('Please upload a PDF file');
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      let item = ev.dataTransfer.files[0];
      if (item.type !== 'application/pdf') {
        alert('Please upload a PDF file');
        return;
      } else {
        setFile(item);
      }
    }
  }
  function dragOverHandler(ev) {
    console.log('File(s) in drop zone');

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }
  function clickHandler(ev) {
    console.log('Upload Area Clicked');
    document.getElementById('fileInput').click();
  }

  const handleFileChange = (event) => {
    // Access the selected file object
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (!file) {
      console.error('No file selected');
      return; // Handle empty selection gracefully
    }
  
    // Process the file (e.g., display its details)
    setFile(file);
    console.log('Selected file:', file); // Example log
  };

  return (
    <div className={Styles.Usage}>
      <div className={Styles.MainWrapper}>
        <div className={Styles.MainContainer}>
          <Panel fill={true}>
            <div className={Styles.ControlsContainer}>
              <div
                className={Styles.Upload}
                onDrop={(e) => dropHandler(e)}
                onDragOver={(e) => dragOverHandler(e)}
                onClick={(e) => clickHandler(e)}
              >
              <input type="file" id="fileInput" name="file" accept="application/pdf" onChange={handleFileChange}/>
              
                {!file ? (
                  <img src={upload} alt='upload' />
                ) : (
                  <div>
                    <h2>Upload Files</h2>
                    <h3>{file.name}</h3>
                  </div>
                )}
              </div>
              <span className={Styles.Seperator} />
              <div className={Styles.Controls}>
                <div className={Styles.Format}>
                  <h3>Output type</h3>
                  <div>
                    <input
                      type='radio'
                      id='markdown'
                      name='output'
                      defaultChecked
                    />
                    <label htmlFor='markdown'>Markdown</label>
                    <input type='radio' id='pdf' name='output' />
                    <label htmlFor='pdf'>PDF</label>
                  </div>
                </div>
                <div className={Styles.Waiting}>
                  {waiting && (
                    <img src={waiting_gif} alt='waiting' width='25' />
                  )}
                  <Button
                    onClick={() => submitHandler()}
                    size='md'
                    label='Generate'
                  />
                </div>
              </div>
            </div>
          </Panel>
          <span
            className={Styles.TriangleShape}
            src={triangle_shape}
            alt='triangle'
          />
        </div>
      </div>
    </div>
  );
};

export default Usage;
