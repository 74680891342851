import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './page/Home/Home';
import About from './page/About/About';
import Usage from './page/Usage/Usage';
import Nav from './components/Nav/Nav';
import Button from './components/Button/Button';
import './App.css';

import Logo from './assets/header/logo.svg';

// import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
function App() {
  return (
    <div className='App'>
      <Router>
        <Nav logo={Logo}>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li>
            <Link to='/about'>About</Link>
          </li>
          <li>
            <Link to='/usage'>
              <Button label='Get Started' size='md' />
            </Link>
          </li>
        </Nav>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/usage' element={<Usage />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
