import React from 'react';
import Styles from './About.module.css';

import Testimonial from '../../components/Testimonial/Testimonial';

const About = ({ text }) => {
  return (
    <div>
      <div className={Styles.MainWrapper}>
        <div className={Styles.MainContainer}>
          <div className={Styles.MainContentBox}>
            <div className={Styles.PrimaryContentLayout}>
              <h1 className={Styles.MainHeading}>About NoteMaker</h1>
              <div className={Styles.MainContent}>
                <ul>
                  <li>
                    <span style={{ marginLeft: '10px' }}>
                      We found that time and time again, we were missing out on
                      useful information that wasn't in our lecture slides
                      whilst frantically making notes in our lectures. This is
                      why we developed{' '}
                      <span className={Styles.Bold}>NoteMaker</span>, to help
                      students make notes more effectively, and to help them
                      keep track of their notes in a more organised manner.
                    </span>
                  </li>
                  <li>
                    <span style={{ marginLeft: '10px' }}>
                      <span className={Styles.Bold}>
                        We use the power of ChatGPT
                      </span>{' '}
                      to extract our notes, which means that you can trust us as
                      much as you can trust one of ChatGPT's{' '}
                      <span className={Styles.Bold}>180 million users</span>{' '}
                      trust it.
                    </span>
                  </li>
                  <li>
                    <span style={{ marginLeft: '10px' }}>
                      NoteMaker isn't a replacement for paying attention, but it
                      should help you to get all of the useful stuff down
                      quickly so that you can focus on the important stuff.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={Styles.SecondaryContent}>
              <div className={Styles.Testimonials}>
                <h1 className={Styles.SubHeading}>Hear from our users:</h1>
                <div className={Styles.TestimonialLayout}>
                  <Testimonial
                    nameOfUser={'Matt A.'}
                    userReview={
                      'NoteMaker helped me to achieve a better grade in my coursework'
                    }
                    starRating={'⭐⭐⭐⭐⭐'}
                  />
                  <Testimonial
                    nameOfUser={'Pedreezy G.'}
                    userReview={
                      "Yooo my slimes. I know you really don't know mandem like that but I need some of the good kush"
                    }
                    starRating={'⭐⭐⭐⭐⭐'}
                  />
                  <Testimonial
                    nameOfUser={'James A.'}
                    userReview={
                      'NoteMaker helped me to get into a scrap with my lecturer'
                    }
                    starRating={'⭐⭐⭐⭐⭐'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
