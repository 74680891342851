import React from 'react';
import PropTypes from 'prop-types';
import Style from './Testimonial.module.css';



const Testimonial = ({
  nameOfUser,
  userReview,
  starRating,
}) => {
  return (
      <div className={Style.Testimonial}>
        <div className={Style.TestimonialContent}>
          <h3>{nameOfUser}</h3>
          <h3>{userReview}</h3>
          <h2>{starRating}</h2>
        </div>
      </div>
  );
};

Testimonial.displayName = 'Testimonial';
Testimonial.propTypes = {
  nameOfUser: PropTypes.string,
  userReview: PropTypes.string,
  starRating: PropTypes.string,
};

export default Testimonial;
