import React from 'react';
import PropTypes from 'prop-types';
import cw from 'classnames';

import Style from './button.module.css';

const Button = ({ label, buttonType, size, onClick, hollow }) => {
  const sizeClass = (size) => {
    switch (size) {
      case 'sm':
        return Style.sm;
      case 'md':
        return Style.md;
      case 'lg':
        return Style.lg;
      default:
        return Style.sm;
    }
  };
  const typeClass = (type) => {
    switch (type) {
      case 'primary':
        return Style.primary;
      case 'secondary':
        return Style.secondary;
      case 'navy':
        return Style.navy;

      default:
        return Style.primary;
    }
  };

  return (
    <button
      onClick={() => (onClick ? onClick() : null)}
      className={cw(
        Style.primary,
        sizeClass(size),
        typeClass(buttonType),
        hollow ? Style.hollow : Style.defaultText
      )}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  buttonType: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onClick: PropTypes.func,
  hollow: PropTypes.bool,
};

export default Button;
