import React from 'react';
import Styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={Styles.Footer}>
        <div className={Styles.MainContainer}>
            <div className={Styles.Links}>
                <ul>
                  <li>
                      <p> © All Rights Reserved</p>
                  </li>
                  <li>
                      <p> <a href="https://github.com/Ben-McIlveen">Ben McIlveen</a> & <a href="https://github.com/danielhartgrove">Daniel Hartgrove</a> 2023</p>
                  </li>
                </ul>

                <ul>
                  <li>
                      <p> © All Rights Reserved</p>
                  </li>
                  <li>
                      <p> <a href="https://github.com/Ben-McIlveen">Ben McIlveen</a> & <a href="https://github.com/danielhartgrove">Daniel Hartgrove</a></p>
                  </li>
                </ul>

                <ul>
                  <li>
                      <p> © All Rights Reserved</p>
                  </li>
                  <li>
                      <p> <a href="https://github.com/Ben-McIlveen">Ben McIlveen</a> & <a href="https://github.com/danielhartgrove">Daniel Hartgrove</a></p>
                  </li>
                </ul>
                  
            </div>

        </div>
    </div>
  );
};

export default Footer;